import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "How to check if number is prime",
  "author": "tzookb",
  "type": "post",
  "date": "2023-01-07T13:50:08.000Z",
  "url": "/check-is-prime/",
  "featuredImage": "./thumb.png",
  "desc": "simple function for checing if a number is a prime number.",
  "categories": ["software", "development", "algorithms"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Steps:`}</p>
    <ul>
      <li parentName="ul">{`check if number is less than 2, if so return false`}</li>
      <li parentName="ul">{`check if number is less than 3, if so return true`}</li>
      <li parentName="ul">{`check if number is divisible by 2, if so return false`}</li>
      <li parentName="ul">{`check if number is divisible by 3, if so return false`}</li>
      <li parentName="ul">{`check if number is divisible by any number between 5 and sqrt(number), if so return false`}</li>
      <li parentName="ul">{`why do we loop up to sqrt(number)?`}
        <ul parentName="li">
          <li parentName="ul">{`because if a number is not a prime number, it can be divided by a number that is smaller than its square root.`}</li>
          <li parentName="ul">{`for example, 12 can be divided by 2, 3, 4, 6, 12. 2 and 3 are smaller than 12's square root (3.46), so we can stop checking after 3.46.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`if we didn't find any number that divides the number, it is a prime number`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`def __is_prime(self, num: int) -> bool:
    if num < 2:
        return False
    if num <= 3:
        return True
    if num % 2 == 0:
        return False
    if num % 3 == 0:
        return False
    for i in range(5, int(math.sqrt(num)) + 1, 2):
        if num % i == 0:
            return False
    return True
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      